import { addScriptContentToHead } from 'BaxterScript/helper/browser/Html';
import { Config } from 'BaxterScript/types/Config';
import { OptanonCategory } from 'BaxterScript/version/web/config/OptanonCategory';

export const webpackExclude = (config: Config): boolean => !config.app?.gemius?.enabled;

const content = (id: string, path: string, className: string) =>
  `
      var pp_gemius_identifier = '${id}';
      var pp_gemius_init = true;
      // lines below shouldn't be edited
      function gemius_pending(i) { window[i] = window[i] || function() {
          var x = window[i+'_pdata'] = window[i+'_pdata'] || [];
          x[x.length]=arguments;};};
      gemius_pending('gemius_hit');
      gemius_pending('gemius_event');
      gemius_pending('pp_gemius_hit');
      gemius_pending('pp_gemius_event');
      (function(d,t) {
          try {
              var gt=d.createElement(t),s=d.getElementsByTagName(t)[0];
              gt.setAttribute('type','application/javascript');
              gt.setAttribute('class','${className}');
              gt.setAttribute('async','async');
              gt.setAttribute('defer','defer');
              gt.src='${path}';
              s.parentNode.insertBefore(gt,s);
          } catch (e) {}
      })(document,'script');
  `;

export const bootstrap = () => {
  const { appId } = globalThis.Baxter.config;
  const { id, path, className } = globalThis.Baxter.config?.app?.gemius || {};
  if (id && path) {
    addScriptContentToHead(`baxter-script-gemius-${appId}`, content(id, path, className || OptanonCategory.C0002));
  }
};

export const gemiusHit = () => {
  console.info(
    '[SLOTS][GEMIUS][GEMIUSHIT]',
    globalThis.Baxter?.config?.app?.gemius?.tracking,
    typeof globalThis.pp_gemius_identifier,
    typeof globalThis.gemius_hit,
    globalThis.pp_gemius_init
  );
  if (
    globalThis.Baxter?.config?.app?.gemius?.tracking &&
    typeof globalThis.pp_gemius_identifier !== 'undefined' &&
    typeof globalThis.gemius_hit !== 'undefined' &&
    globalThis.pp_gemius_init === false
  ) {
    console.debug('[SLOTS][GEMIUS][GEMIUSHIT] send hit');
    globalThis.gemius_hit(globalThis.Baxter.config?.app?.gemius?.id);
  }

  if (typeof globalThis.pp_gemius_init !== 'undefined') {
    console.debug('[SLOTS][GEMIUS][GEMIUSHIT] pp_gemius_init = false');
    globalThis.pp_gemius_init = false;
  }
};

// eslint-disable-next-line import/no-default-export
export default {
  bootstrap,
  gemiusHit,
};

export enum NinjaMetric {
  INTERSTITIAL_IMPRESSION = 'interstitial_impression',
  INTERSTITIAL_VIEWABLE = 'interstitial_viewable',
  INTERSTITIAL_CLICKED = 'interstitial_clicked',
  ADVERTISEMENT_DISPLAYED = 'advertisement_displayed',
  ADVERTISEMENT_VIEWED = 'advertisement_viewed',
  ADVERTISEMENT_CLICKED = 'advertisement_clicked',
  ADVERTISEMENT_INTERACTION = 'advertisement_interaction',
  STICKY_AD_DISPLAYED = 'sticky_ad_displayed',
  STICKY_AD_CLOSED = 'sticky_ad_closed',
}

import { NewRelicMetrics } from 'BaxterScript/helper/metrics/NewRelicMetrics';
import { NewRelicError } from 'BaxterScript/helper/metrics/NewRelicError';
import { NewRelicMetric } from 'BaxterScript/helper/metrics/NewRelicMetric';

export class BaxterNewRelicMetrics {
  constructor(
    private newRelicMetrics: NewRelicMetrics,
    private metricsSampling = {
      [NewRelicMetric.LIFECYCLE_ON_SET]: 1,
      [NewRelicError.LIFECYCLE_ON_SET_DEBOUNCED]: 10,
      [NewRelicMetric.CONTAINER_SET_SLOT]: 1,
      [NewRelicMetric.CONTAINER_NO_MATCHING_SLOT]: 1,
      [NewRelicMetric.CONTAINER_LOADABLE]: 1,
      [NewRelicMetric.CONTAINER_SLOT_LAZY_LOAD_APPLIED]: 1,
      [NewRelicError.CONTAINER_DIV_NOT_FOUND]: 1,
      [NewRelicError.CONTAINER_INNER_DIV_NOT_FOUND]: 10,
      [NewRelicError.CONTAINER_SET_SLOT_ERROR]: 10,
      [NewRelicError.CONTAINER_SET_TOO_RECENTLY]: 10,
      [NewRelicMetric.CONSENT_RESOLVED]: 2,
      [NewRelicMetric.CONSENT_MISSING_ACTIVE_GROUPS]: 2,
      [NewRelicMetric.AD_CLICKED]: 10,
      [NewRelicMetric.SEGMENTATION_LOGGED_IN_REQUEST]: 1,
      [NewRelicMetric.SEGMENTATION_LOGGED_OUT_REQUEST]: 1,
      [NewRelicMetric.SEGMENTATION_LOGGED_IN_RESPONSE_NO_SEGMENTS]: 1,
      [NewRelicMetric.SEGMENTATION_LOGGED_OUT_RESPONSE_NO_SEGMENTS]: 1,
      [NewRelicMetric.LIFECYCLE_SEGMENT_DEPRECATED_USAGE]: 1,
      [NewRelicMetric.LIFECYCLE_ON_PAGE_TARGETING_PARAMS]: 1,
    },
    private accountSampling: Record<string, number> = {
      otodompl: 100,
      imovirtualpt: 100,
      storiaro: 100,
    },
    private defaultSampling = 10
  ) {}

  sample(name: NewRelicError | NewRelicMetric, accountId?: string) {
    return this.metricsSampling[name] || (accountId && this.accountSampling[accountId]) || this.defaultSampling;
  }

  sendMetric(name, parameters, sample) {
    if (globalThis.Baxter?.state?.featureFlags) {
      parameters.featureFlag = globalThis.Baxter?.state?.featureFlags;
    }
    if (globalThis.Baxter?.state?.abTest) {
      parameters.abTest = globalThis.Baxter?.state?.abTest;
    }
    const baxterizedParameters = this.addBaxterPrefix(parameters);
    if (globalThis.Baxter && globalThis.Baxter.config) {
      const { accountId, versionId, appId, commitHash } = globalThis.Baxter.config;
      const baxterizedConfigInfo = this.addBaxterPrefix({ accountId, versionId, appId, commitHash });
      this.newRelicMetrics.sendMetric(name, { ...baxterizedConfigInfo, ...baxterizedParameters }, sample);
    } else {
      this.newRelicMetrics.sendMetric(name, baxterizedParameters, sample);
    }
  }

  reportMetric(name: NewRelicMetric, parameters = {}) {
    try {
      this.sendMetric(name, { ...parameters }, this.sample(name, globalThis.Baxter?.config?.accountId));
    } catch (err) {
      console.error(err);
    }
  }

  reportError(errorCode: NewRelicError, parameters = {}) {
    try {
      this.sendMetric(
        'BaxterError',
        { errorCode, ...parameters },
        this.sample(errorCode, globalThis.Baxter?.config?.accountId)
      );
    } catch (err) {
      console.error(err);
    }
  }

  addBaxterPrefix(parameters) {
    const baxterized: Record<string, unknown> = {};
    for (const [key, value] of Object.entries(parameters)) {
      baxterized[`baxter${key.charAt(0).toUpperCase() + key.slice(1)}`] = value;
    }
    return baxterized;
  }
}

export default new BaxterNewRelicMetrics(new NewRelicMetrics(3000));

import { Slot } from 'BaxterScript/types/Slot';
import newRelicMetrics from 'BaxterScript/helper/metrics/BaxterNewRelicMetrics';
import { NewRelicError } from 'BaxterScript/helper/metrics/NewRelicError';
import { Providers } from 'BaxterScript/version/web/config/Providers';
import ninjaMetrics, { NinjaMetrics } from 'BaxterScript/helper/metrics/NinjaMetrics';
import { NinjaMetric } from 'BaxterScript/helper/metrics/NinjaMetric';
import { NinjaEventType } from 'BaxterScript/helper/metrics/NinjaEventType';

type ProviderSpecificTracker = () => Promise<void>;

export const onClickHandler = (
  provider: Providers.AD_MANAGER_STATIC | Providers.AD_MANAGER,
  source: string,
  slot: Slot,
  parameters: Record<string, unknown> = {},
  providerSpecificTracker?: ProviderSpecificTracker,
  destinationUrl?: string
) => {
  let preventDoubleClick = false;
  let preventMultipleClickTrackingRequests = false;
  return async () => {
    try {
      console.info(`[SLOTS][${provider.toUpperCase()}][ONCLICKHANDLER]`, source, slot, destinationUrl, parameters);
      if (!preventDoubleClick) {
        preventDoubleClick = true;
        if (!preventMultipleClickTrackingRequests) {
          preventMultipleClickTrackingRequests = true;
          if (providerSpecificTracker) {
            await providerSpecificTracker();
          }
          slot[provider].callbacks.slotClickedCallback(source, slot, parameters);
        }
        if (destinationUrl) {
          window.open(destinationUrl, '_blank');
        }
        preventDoubleClick = false;
      }
    } catch (e) {
      console.error(`[SLOTS][${provider.toUpperCase()}][ONCLICKHANDLER]`, e);
      newRelicMetrics.reportError(NewRelicError.SLOT_CLICK_HANDLER_ERROR, { message: (e as Error).message });
    }
  };
};

export const addClickListener = (slot: Slot, onClick: (event: Event) => Promise<void>) => {
  const elements = slot.innerHtmlElement.getElementsByClassName('baxter-destination-click-handler');
  if (elements.length) {
    for (const element of elements) {
      element.addEventListener('click', onClick);
    }
  }
};

const onInteractionHandler = async (slot: Slot, source: string, params: Record<string, unknown>) => {
  try {
    console.info(`[SLOTS][${slot.provider.toUpperCase()}][ONINTERACTIONHANDLER]`, slot);
    ninjaMetrics.reportMetric(
      NinjaMetric.ADVERTISEMENT_INTERACTION,
      NinjaMetrics.extendedParameters(source, slot, NinjaEventType.CLICK, params)
    );
  } catch (e) {
    console.error(`[SLOTS][${slot.provider.toUpperCase()}][ONINTERACTIONHANDLER]`, e);
    newRelicMetrics.reportError(NewRelicError.SLOT_INTERACTION_HANDLER_ERROR, {
      message: (e as Error).message,
      providerId: slot.provider,
    });
  }
};

export const addInteractionListener = (slot: Slot, source: string) => {
  const attributeName = 'data-baxter-interaction';
  [...slot.innerHtmlElement.querySelectorAll(`[${attributeName}]`)].forEach((element: HTMLElement) => {
    const baxterInteractionTrackingParams = Object.fromEntries(
      Object.entries(element.dataset)
        .filter(([key]) => key !== 'baxterInteraction' && key.startsWith('baxterInteraction'))
        .map(([key, value]) => [key.replace('baxterInteraction', '').toLowerCase(), value])
    );
    const interactionType = element.dataset.baxterInteraction;
    if (interactionType) {
      element.addEventListener(
        interactionType,
        () => onInteractionHandler(slot, source, baxterInteractionTrackingParams),
        { once: true }
      );
    }
  });
};

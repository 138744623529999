import * as State from 'BaxterScript/version/web/core/State';
import * as Request from 'BaxterScript/helper/browser/Request';
import { SegmentationIdType } from 'BaxterScript/version/web/config/SegmentationIdType';
import newRelicMetrics from 'BaxterScript/helper/metrics/BaxterNewRelicMetrics';
import { NewRelicMetric } from 'BaxterScript/helper/metrics/NewRelicMetric';
import { NewRelicError } from 'BaxterScript/helper/metrics/NewRelicError';
import Consent from 'BaxterScript/version/web/feature/Consent';

const FIVE_MINUTES_IN_MILLIS = 5 * 60 * 1000;

const shouldFetchSegments = (requestCount: number, lastRequestTime: number) => {
  const now = Date.now();
  const segmentationJobsFinishedTime = new Date().setUTCHours(5, 0, 0, 0);
  switch (requestCount) {
    case 0:
      return now >= lastRequestTime;
    case 1:
      return now >= lastRequestTime + FIVE_MINUTES_IN_MILLIS;
    default:
      return lastRequestTime < segmentationJobsFinishedTime && now > segmentationJobsFinishedTime;
  }
};

const getUserStatus = (idType: SegmentationIdType) => (idType === SegmentationIdType.USER_ID ? 1 : 0);

const fetchSegments = async (requestCount: number, id: string, idType: SegmentationIdType) => {
  try {
    const { accountId } = globalThis.Baxter.config;
    console.debug('[SLOTS][SEGMENTATION][SCHEDULEFETCHINGSEGMENTS] fetch segment');
    newRelicMetrics.reportMetric(
      idType === SegmentationIdType.USER_ID
        ? NewRelicMetric.SEGMENTATION_LOGGED_IN_REQUEST
        : NewRelicMetric.SEGMENTATION_LOGGED_OUT_REQUEST
    );
    State.setSegmenationRequestInProgress(true);
    const response = await Request.get(
      `${globalThis.Baxter.config.unifiedSegmentationApiDomain}/v1/${accountId}/user-segments/${id}`,
      true
    );
    State.setSegmenationRequestInProgress(false);
    if (!response.length) {
      newRelicMetrics.reportMetric(
        idType === SegmentationIdType.USER_ID
          ? NewRelicMetric.SEGMENTATION_LOGGED_IN_RESPONSE_NO_SEGMENTS
          : NewRelicMetric.SEGMENTATION_LOGGED_OUT_RESPONSE_NO_SEGMENTS
      );
    }
    if (Consent?.isUserConsentC0004Given() ?? true) {
      State.setSegmentationData({
        userStatus: getUserStatus(idType),
        ...(idType === SegmentationIdType.SESSION_LONG ? { id } : {}),
        requestCount: requestCount + 1,
        lastRequestTime: Date.now(),
        segmentationData: response,
      });
    }
  } catch (error) {
    State.setSegmenationRequestInProgress(false);
    console.error('[SLOTS][SEGMENTATION][SCHEDULEFETCHINGSEGMENTS]', error);
    newRelicMetrics.reportError(NewRelicError.SEGMENTATION_REQUEST_ERROR);
  }
};

const evaluateIdAndIdType = (
  userLoggedIn?: boolean,
  userId?: string,
  sessionLong?: string
): { id: string; idType: SegmentationIdType } => {
  let id;
  let idType;
  if (userLoggedIn) {
    if (!userId) {
      id = sessionLong;
      idType = SegmentationIdType.SESSION_LONG;
      console.error('[SLOTS][SEGMENTATION][PROPAGATESEGMENTSINSTATE] user logged in but no user id');
      newRelicMetrics.reportError(NewRelicError.SEGMENTATION_USER_LOGGED_IN_BUT_NO_USER_ID);
    } else {
      id = userId;
      idType = SegmentationIdType.USER_ID;
    }
  } else {
    id = sessionLong;
    idType = SegmentationIdType.SESSION_LONG;
  }
  return {
    id,
    idType,
  };
};

export const removeSegments = () => {
  State.setSegmenationRequestInProgress(false);
  State.removeSegmentationData();
};

export const propagateSegments = (userLoggedIn?: boolean, userId?: string, sessionLong?: string) => {
  try {
    if (!globalThis.Baxter.config.accountId.startsWith('olx') && globalThis.Baxter.config.accountId !== 'client') {
      return;
    }
    if (State.getSegmenationRequestInProgress()) {
      console.error('[SLOTS][SEGMENTATION][PROPAGATESEGMENTS] segmentation request in progress');
      newRelicMetrics.reportError(NewRelicError.SEGMENTATION_REQUEST_IN_PROGRESS);
      return;
    }
    if (!userId && !sessionLong) {
      console.error('[SLOTS][SEGMENTATION][PROPAGATESEGMENTS] missing user id and session long');
      newRelicMetrics.reportError(NewRelicError.SEGMENTATION_MISSING_USER_ID_AND_SESSION_LONG);
      return;
    }
    if (!userLoggedIn && !sessionLong) {
      console.error('[SLOTS][SEGMENTATION][PROPAGATESEGMENTS] user logged out but no session long');
      newRelicMetrics.reportError(NewRelicError.SEGMENTATION_USER_LOGGED_OUT_BUT_NO_SESSION_LONG);
      return;
    }
    const { id, idType } = evaluateIdAndIdType(userLoggedIn, userId, sessionLong);
    let segmentationData = State.getSegmentationData();
    if (
      segmentationData?.userStatus !== getUserStatus(idType) ||
      (getUserStatus(idType) === 0 && id !== segmentationData.id)
    ) {
      removeSegments();
      segmentationData = undefined;
    }
    if (segmentationData) {
      const requestCount = segmentationData.requestCount || 0;
      const lastRequestTime = segmentationData.lastRequestTime || 0;
      if (shouldFetchSegments(requestCount, lastRequestTime)) {
        // not awaiting on purpose to not make this method async
        fetchSegments(requestCount, id, idType);
      }
    } else {
      // not awaiting on purpose to not make this method async
      fetchSegments(0, id, idType);
    }
  } catch (err) {
    console.error('[SLOTS][SEGMENTATION][PROPAGATESEGMENTS]', err);
    newRelicMetrics.reportError(NewRelicError.SEGMENTATION_ERROR);
  }
};

export const getSegment = () => State.getSegmentationData()?.segmentationData;

import { ContainerConfig } from 'BaxterScript/types/Config';
import * as Objects from 'BaxterScript/helper/object/Object';
import * as State from 'BaxterScript/version/web/core/State';

export const createTargetingParams = (pageId: string, containerConfig: ContainerConfig) =>
  Objects.clone({
    ...State.getPageParams(),
    ...containerConfig.data,
    container: containerConfig.id,
  });

import * as Prebid from 'BaxterScript/version/web/provider/google/Prebid';
import newRelicMetrics from 'BaxterScript/helper/metrics/BaxterNewRelicMetrics';
import { NewRelicError } from 'BaxterScript/helper/metrics/NewRelicError';
import { NewRelicMetric } from 'BaxterScript/helper/metrics/NewRelicMetric';
import { GoogleAdsSlotExternal, GoogleAdsInitializedBiddersPrebid, GoogleAdsSlot } from 'BaxterScript/types/Slot';
import { Providers } from 'BaxterScript/version/web/config/Providers';
import { Config } from 'BaxterScript/types/Config';
import {
  GoogleAdsApsConfig,
  GoogleAdsConfig,
  GoogleAdsPrebidConfig,
} from 'BaxterScript/types/ProviderSlotConfig/GoogleAds';
import { TargetingParams } from 'BaxterScript/types/TargetingParams';

export const id = Providers.GOOGLE_ADS;

export const webpackExclude = (config: Config) => {
  const providerSettings = (config.slots?.providerSettings?.[id] ?? {}) as GoogleAdsConfig;
  const prebidSettings = providerSettings.prebid;
  return !(
    (prebidSettings?._ && Object.values(prebidSettings._).some((item) => item?.enabled === true)) ||
    (prebidSettings && Object.values(prebidSettings).some((item) => item?.enabled === true))
  );
};

export const init = () => {
  console.info('[SLOTS][GOOGLEADSPREBID][INIT]');
  Prebid.init();
};

export const dependencies = () => {
  console.info('[SLOTS][GOOGLEADSPREBID][DEPENDENCIES]');
  return Prebid.dependencies();
};

export const loaded = () => {
  console.info('[SLOTS][GOOGLEADSPREBID][LOADED]');
  Prebid.loaded();
};

export const initialize = (
  slotConfig: GoogleAdsPrebidConfig | GoogleAdsApsConfig
): GoogleAdsInitializedBiddersPrebid => {
  console.info('[SLOTS][GOOGLEADSPREBID][INITIALIZE]', slotConfig);
  return { sizes: Prebid.initialize(slotConfig) };
};

const getPrebidExternal = (slot: GoogleAdsSlot): GoogleAdsSlotExternal[] => {
  const external: GoogleAdsSlotExternal[] = [];
  const bids = Prebid.getBids(slot[id].initialized.targeting, slot[id].providerConfig, slot[id].config.prebid.bidders);
  const sizes = slot[id].initialized.bidders?.prebid?.sizes;
  let externalBase = {};
  if (sizes?.length) {
    externalBase = {
      sizes,
      mediaTypes: {
        banner: { sizes },
      },
    };
  }
  if (bids.path) {
    external.push({
      ...externalBase,
      code: slot[id].initialized.path,
      bids: bids.path,
    });
  }
  if (bids.id) {
    external.push({
      ...externalBase,
      code: slot.innerId,
      bids: bids.id,
    });
  }

  return external;
};

export const create = (slot: GoogleAdsSlot, biddersCallback: () => void): void => {
  console.info('[SLOTS][GOOGLEADSPREBID][CREATE]', slot);
  const { pbjs } = globalThis;
  const external = getPrebidExternal(slot);
  console.debug('[SLOTS][GOOGLEADSPREBID][CREATE]', slot, external);
  pbjs.que.push(() => {
    try {
      pbjs.addAdUnits(external);
      biddersCallback();
    } catch (e) {
      console.error('[SLOTS][GOOGLEADSPREBID][CREATE]', e);
      newRelicMetrics.reportError(NewRelicError.PREBID_QUE_ERROR, {
        command: '[GOOGLEADSPREBIDCREATE]',
        message: (e as Error).message,
      });
      throw e;
    }
  });
};

export const load = (prebidSlots: GoogleAdsSlot[], biddersCallback: () => void) => {
  console.info('[SLOTS][GOOGLEADSPREBID][LOAD]', prebidSlots);
  const { pbjs } = globalThis;
  pbjs.que.push(() => {
    try {
      const slotsToLoad = prebidSlots.filter((slot) => {
        if (slot[id].state.alreadyRemoved) {
          console.debug('[SLOTS][GOOGLEADSPREBID][LOAD] slot already removed', slot);
          newRelicMetrics.reportMetric(NewRelicMetric.GOOGLEADS_SLOT_ALREADY_REMOVED, { place: 'prebidLoad' });
          return false;
        }
        return true;
      });
      if (!slotsToLoad.length) {
        biddersCallback();
        return;
      }
      console.debug('[SLOTS][GOOGLEADSPREBID][LOAD] requesting bids');
      const slotCodes = [
        ...slotsToLoad.map((slot) => slot.innerId),
        ...slotsToLoad.map((slot) => slot[id].initialized.path),
      ];
      newRelicMetrics.reportMetric(NewRelicMetric.PREBID_REQUESTED_BIDS);
      pbjs.requestBids({
        adUnitCodes: slotCodes,
        bidsBackHandler: (bids, timedOut) => {
          try {
            if (timedOut) {
              console.debug('[SLOTS][GOOGLEADSPREBID][LOAD] timed out', bids);
              newRelicMetrics.reportMetric(NewRelicMetric.PREBID_TIMED_OUT);
            } else {
              console.debug('[SLOTS][GOOGLEADSPREBID][LOAD] successfully returned', bids);
              newRelicMetrics.reportMetric(NewRelicMetric.PREBID_SUCCESSFULLY_RETURNED);
            }
            const loadedSlots = slotsToLoad.filter((slot) => {
              if (slot[id].state.alreadyRemoved) {
                console.debug('[SLOTS][GOOGLEADSPREBID][LOAD] slot already removed', slot);
                newRelicMetrics.reportMetric(NewRelicMetric.GOOGLEADS_SLOT_ALREADY_REMOVED, {
                  place: 'prebidCallback',
                });
                return false;
              }
              return true;
            });
            const loadedSlotCodes = [
              ...loadedSlots.map((slot) => slot.innerId),
              ...loadedSlots.map((slot) => slot[id].initialized.path),
            ];
            if (loadedSlotCodes.length) {
              globalThis.googletag.cmd.push(() => {
                try {
                  console.debug(
                    '[SLOTS][GOOGLEADSPREBID][LOAD] pbjs.setTargetingForGPTAsync(...)',
                    bids,
                    loadedSlotCodes
                  );
                  pbjs.setTargetingForGPTAsync(loadedSlotCodes);
                } catch (e) {
                  console.error('[SLOTS][GOOGLEADSPREBID][LOAD]', e);
                  newRelicMetrics.reportError(NewRelicError.GOOGLEADS_COMMAND_ERROR, {
                    command: '[SETTARGETINGFORGPTASYNC]',
                    message: (e as Error).message,
                  });
                  throw e;
                }
              });
            }
            biddersCallback();
          } catch (e) {
            console.error('[SLOTS][GOOGLEADSPREBID][LOAD]', e);
            newRelicMetrics.reportError(NewRelicError.PREBID_QUE_ERROR, {
              command: '[GOOGLEADSPREBIDLOAD][BIDSBACKHANDLER]',
              message: (e as Error).message,
            });
            throw e;
          }
        },
      });
    } catch (e) {
      console.error('[SLOTS][GOOGLEADSPREBID][LOAD]', e);
      newRelicMetrics.reportError(NewRelicError.PREBID_QUE_ERROR, {
        command: '[GOOGLEADSPREBIDLOAD]',
        message: (e as Error).message,
      });
      throw e;
    }
  });
};

export const remove = (slots: GoogleAdsSlot[] = []) => {
  console.info('[SLOTS][GOOGLEADSPREBID][REMOVE]', slots);
  return Prebid.remove(slots.filter((slot) => slot[id].config.prebid.enabled).map((slot) => slot[id].initialized.path));
};

export const setPageTargeting = (params: TargetingParams): void => {
  console.info('[SLOTS][GOOGLEADSPREBID][SETPAGETARGETING]', params);
  Prebid.setPageTargeting(params);
};

// eslint-disable-next-line import/no-default-export
export default {
  init,
  dependencies,
  loaded,
  initialize,
  create,
  load,
  remove,
};

import { FallbackSlot, Slot } from 'BaxterScript/types/Slot';
import { NewRelicError } from 'BaxterScript/helper/metrics/NewRelicError';
import * as Provider from 'BaxterScript/version/web/core/Provider';
import { Features } from 'BaxterScript/version/web/config/Features';
import { ContainerType, FindAndSetAndCreateSlotFunction } from 'BaxterScript/types/ContainerType';
import { Config } from 'BaxterScript/types/Config';
import * as Observer from 'BaxterScript/helper/browser/Observer';

export const webpackExclude = (config: Config): boolean =>
  !Object.values(config.containers).some((pageContainers) =>
    pageContainers.some((pageContainer) => pageContainer.fallbackEnabled)
  );

export const id = Features.FALLBACK;

const loadSlot = async (
  container: ContainerType,
  fallbackSlot: FallbackSlot,
  findAndSetAndCreateSlot: FindAndSetAndCreateSlotFunction
) => {
  const loadable = findAndSetAndCreateSlot(id, fallbackSlot.pageId, container, fallbackSlot.params, true);
  if (loadable) {
    await Provider.load(id, [container.state.slot as Slot]);
  }
};

const removeObserver = (container: ContainerType) => {
  console.info('[SLOTS][FALLBACK][REMOVEOBSERVER]', container);
  if (container.state[id]) {
    Observer.removeObserver(container.state.htmlElement as HTMLElement, container.state[id]);
  }
};

const addIntersectionObserver = (
  container: ContainerType,
  fallbackSlot: FallbackSlot,
  findAndSetAndCreateSlot: FindAndSetAndCreateSlotFunction
): void => {
  console.info('[SLOTS][FALLBACK][ADDINTERSECTIONOBSERVER]', container);
  const fallbackDistanceFromViewport = container.config.fallbackDistanceFromViewport || 0;
  const rootMargin = `${fallbackDistanceFromViewport}px 0px ${fallbackDistanceFromViewport}px 0px`;
  // eslint-disable-next-line no-param-reassign
  container.state[id]!.intersectionObserver = Observer.createIntersectionObserver(
    container.state.htmlElement as HTMLElement,
    {
      rootMargin,
      onIntersect: async () => {
        if (!container.state[id]!.loaded) {
          // eslint-disable-next-line no-param-reassign
          container.state[id]!.loaded = true;
          removeObserver(container);
          await loadSlot(container, fallbackSlot, findAndSetAndCreateSlot);
        }
      },
    },
    'FALLBACK',
    NewRelicError.FALLBACK_INTERSECTION_OBSERVER_ERROR
  );
};

export const alreadyApplied = (container: ContainerType, slotId: string): boolean =>
  !!container.state?.[id]?.noFills?.[slotId];

const apply = (
  container: ContainerType,
  slot: Slot,
  findAndSetAndCreateSlot: FindAndSetAndCreateSlotFunction
): boolean => {
  if (!container.config.fallbackEnabled) {
    return false;
  }
  if (alreadyApplied(container, slot.id)) {
    return true;
  }
  if (!container.state[id]) {
    // eslint-disable-next-line no-param-reassign
    container.state[id] = {
      noFills: {},
    };
  }
  // eslint-disable-next-line no-param-reassign
  container.state[id].loaded = false;
  // eslint-disable-next-line no-param-reassign
  container.state[id].noFills[slot.id] = true;

  addIntersectionObserver(container, slot as FallbackSlot, findAndSetAndCreateSlot);

  return true;
};

export const remove = (container: ContainerType): void => {
  if (container.config.fallbackEnabled) {
    console.info('[SLOTS][FALLBACK][REMOVE]', container);
    removeObserver(container);
    if (container.state[id]) {
      // eslint-disable-next-line no-param-reassign
      container.state[id].loaded = false;
    }
  }
};

// eslint-disable-next-line import/no-default-export
export default {
  apply,
  remove,
  alreadyApplied,
};

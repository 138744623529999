import { NewRelicError } from 'BaxterScript/helper/metrics/NewRelicError';
import { errorLogger } from 'BaxterScript/helper/logger/logger';

export interface ResizeObserverConfig {
  onResize: (entry: ResizeObserverEntry) => void;
}

export interface IntersectionObserverConfig {
  threshold?: number | number[];
  rootMargin?: string;
  onIntersect: (entry: IntersectionObserverEntry) => void | Promise<void>;
}

export interface ObserverState {
  intersectionObserver?: IntersectionObserver;
  resizeObserver?: ResizeObserver;
}

export const removeObserver = (element: HTMLElement, state: ObserverState): void => {
  if (state.intersectionObserver) {
    state.intersectionObserver.unobserve(element);
    // eslint-disable-next-line no-param-reassign
    state.intersectionObserver = undefined;
  }
  if (state.resizeObserver) {
    state.resizeObserver.unobserve(element);
    // eslint-disable-next-line no-param-reassign
    state.resizeObserver = undefined;
  }
};

export const createResizeObserver = (
  element: HTMLElement,
  config: ResizeObserverConfig,
  logFeature: string,
  newRelicErrorId: NewRelicError
): ResizeObserver => {
  const observer = new ResizeObserver((entries: ResizeObserverEntry[]) => {
    entries.forEach((entry) => {
      console.info(`[SLOTS][${logFeature}][RESIZEOBSERVER]`, entry);
      try {
        config.onResize(entry);
      } catch (e) {
        errorLogger([logFeature, 'RESIZEOBSERVER'], newRelicErrorId, e as Error);
      }
    });
  });

  observer.observe(element);
  return observer;
};

export const createIntersectionObserver = (
  element: HTMLElement,
  config: IntersectionObserverConfig,
  loggerId: string,
  newRelicErrorId: NewRelicError
): IntersectionObserver => {
  const observer = new IntersectionObserver(
    (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        console.info(`[SLOTS][${loggerId}][INTERSECTIONOBSERVER]`, entry.isIntersecting, entry.intersectionRatio);
        try {
          if (entry.isIntersecting) {
            config.onIntersect(entry);
          }
        } catch (e) {
          errorLogger([loggerId, 'INTERSECTIONOBSERVER'], newRelicErrorId, e as Error);
        }
      });
    },
    {
      threshold: config.threshold,
      rootMargin: config.rootMargin,
    }
  );

  observer.observe(element);
  return observer;
};

import * as Objects from 'BaxterScript/helper/object/Object';
import * as State from 'BaxterScript/version/web/core/State';
import { getConfigById } from 'BaxterScript/helper/config/Config';

const addToTargeting = (slot, targeting) => {
  slot.targeting = { ...slot.targeting, ...targeting };
};

const initializeTargeting = (slot, providerSettings, providerConfig, pageId, containerId, slotId, params) => {
  if (providerSettings.targeting) {
    const ranges = globalThis.Baxter.config.app?.ranges;
    const defaultTargetingMap = providerConfig?.targeting || [];
    const slotTargeting = getConfigById(providerSettings.targeting, pageId, containerId, slotId);
    addToTargeting(slot, Objects.parseMap(defaultTargetingMap, params, ranges));
    if (slotTargeting) {
      addToTargeting(slot, Objects.parseMap(slotTargeting.map || [], params, ranges));
    }
    const previewToken = State.getPreviewToken();
    if (previewToken) {
      addToTargeting(slot, { PreviewToken: previewToken });
    }
    const cxSegments = State.getCxenseSegments();
    if (cxSegments) {
      addToTargeting(slot, { CxSegments: cxSegments });
    }
    addToTargeting(slot, {
      baxter_account: globalThis.Baxter.config.accountId,
      baxter_app: globalThis.Baxter.config.appId,
      baxter_environment: globalThis.Baxter.config.environment,
      baxter_version: globalThis.Baxter.config.versionId,
      baxter_page: pageId,
      baxter_container: containerId,
      baxter_slot: slotId,
      position: slotId, // TODO: deprecate this for 'slot' (ask adops about timeline)
      'adblock-status': State.getAdblockStatus() ? '1' : '0', // TODO: deprecate this for 'adblock' (ask adops about timeline)
    });
  }
};

export { initializeTargeting };

import newRelicMetrics from 'BaxterScript/helper/metrics/BaxterNewRelicMetrics';
import { NewRelicError } from 'BaxterScript/helper/metrics/NewRelicError';

export const errorLogger = (path: string[], newRelicErrorId: NewRelicError, error: Error): void => {
  console.error(`[SLOTS]${path.length ? `[${path.join('][')}]` : ''}`, error);

  newRelicMetrics.reportError(newRelicErrorId, {
    message: error.message,
  });
};

import newRelicMetrics from 'BaxterScript/helper/metrics/BaxterNewRelicMetrics';
import { NewRelicError } from 'BaxterScript/helper/metrics/NewRelicError';
import { NinjaMetric } from 'BaxterScript/helper/metrics/NinjaMetric';
import { Slot } from 'BaxterScript/types/Slot';
import { NinjaEventType } from 'BaxterScript/helper/metrics/NinjaEventType';

export class NinjaMetrics {
  private waitForLoadTime: number;

  private sample: number;

  constructor(waitForLoadTime, sample) {
    this.waitForLoadTime = waitForLoadTime;
    this.sample = sample;
  }

  sendMetric(name, parameters = {}, skipSampling = false) {
    Object.keys(parameters).forEach((p) => {
      if (typeof parameters[p] === 'object') parameters[p] = JSON.stringify(parameters[p]);
    });
    if (!globalThis.dataLayer) {
      setTimeout(() => {
        try {
          this.doSendIfSampling(skipSampling, name, parameters);
        } catch (e) {
          console.error(`[SLOTS][NINJAMETRICS][SENDMETRIC] ${name}`, parameters, e);
          newRelicMetrics.reportError(NewRelicError.NINJA_REPORTING_ERROR, { message: (e as Error).message });
        }
      }, this.waitForLoadTime);
    } else {
      this.doSendIfSampling(skipSampling, name, parameters);
    }
  }

  static extendedParameters(
    source: string,
    slot: Slot,
    eventType: NinjaEventType,
    parameters: Record<string, unknown> = {}
  ) {
    return {
      ...parameters,
      ad_slot_id: slot.id,
      ad_request_source: source,
      provider_id: slot.provider,
      ad_container_id: slot.containerId,
      event_type: eventType,
    };
  }

  reportMetric(name: NinjaMetric, parameters) {
    try {
      this.sendMetric(name, parameters);
    } catch (err) {
      console.error('[SLOTS][NINJAMETRICS]', err);
      newRelicMetrics.reportError(NewRelicError.NINJA_REPORTING_ERROR, { message: (err as Error).message });
    }
  }

  doSendIfSampling(skipSampling, name, parameters) {
    const random = Math.random();
    const randomNum = Math.floor(random * 100);
    if (skipSampling || randomNum < this.sample) {
      this.doSend(name, parameters);
    } else {
      console.debug(`[SLOTS][NINJAMETRICS][DOSENDIFSAMPLING] skip because of sampling ${name}`, parameters);
    }
  }

  doSend(name, parameters) {
    console.debug(
      `[SLOTS][NINJAMETRICS][DOSEND] ${globalThis.dataLayer ? 'SENDING ' : 'NO NINJA FOR '} ${name}`,
      parameters
    );
    if (globalThis.dataLayer) {
      globalThis.dataLayer.push({
        trackEvent: [name],
        action_type: name,
        ...parameters,
      });
    }
  }
}

export default new NinjaMetrics(3000, 100);

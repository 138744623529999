export const parseStyleToObject = (styleString: string = ''): Record<string, string> => {
  const styleObject: Record<string, string> = {};

  if (!styleString) {
    return styleObject;
  }

  const declarations = styleString.split(';');

  declarations.forEach((declaration) => {
    const colonIndex = declaration.indexOf(':');

    if (colonIndex !== -1) {
      const property = declaration.substring(0, colonIndex).trim();
      const value = declaration.substring(colonIndex + 1).trim();

      if (property && value) {
        styleObject[property] = value;
      }
    }
  });

  return styleObject;
};

export const convertStyleObjectToString = (styleObject = {}) =>
  Object.entries(styleObject)
    .map(([prop, value]) => `${prop}: ${value};`)
    .join(' ');

export const extractNumberFromStyle = (value: string): number | null => {
  if (!value || typeof value !== 'string') return null;
  const result = parseFloat(value);
  return Number.isNaN(result) ? null : result;
};
